import { Component, OnInit } from '@angular/core';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

  public languageCode: string;

  constructor(
  public translate: TranslateService) { }

  setInCorrectLanguage(): void {
    if (this.translate.currentLang === 'nl') {
      this.languageCode = this.translate.currentLang;
    }
    else if (this.translate.currentLang === 'fr') {
      this.languageCode = this.translate.currentLang;
    }
    else {
      // EN
      this.languageCode = 'en';
    }
  }

  ngOnInit() {
    this.setInCorrectLanguage();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setInCorrectLanguage();
    });
  }

}
