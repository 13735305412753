<div class="forSchools">
  <div class="forSchoolsMainSection">
    <h2 class="text-center"  [translate]="'forschools.title'">Language Hero for schools</h2>

    <div class="container">
      <p>TODO:</p>

      <ol>
        <li>HelpDesk: helpdesk@taalheld.be</li>
        <li>accounts maken -> normaal niet nodig, best via i-learn gaan</li>
        <li>hardware normaal quasi over ok dankzij WEBGL; voor spraak/geluid nog wel outputdevice + microfoon nodig</li>
        <li>opties toelichten uit instellingenscherm: niveau, automatisch aanpassen niveau, doeltaal, interfacetaal,...</li>
        <li>content opgelijst: inhoud per onderdeel + zeggen hoeveel content per onderdeel?</li>
      </ol>
    </div>
  </div>
</div>
