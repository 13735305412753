<div class="productDetail">
  <div class="coreFeaturesSection">
    <h2 class="text-center"  [translate]="'corefeatures.title'">Core features</h2>

    <div class="container">
      <div class="row pt-1 pb-1">
        <div class="col-lg-7">
          <h4 [translate]="'corefeatures.open_answers'">Open antwoorden</h4>

          <p [innerHtml]="'corefeatures.open_answers_paragraph_1' | translate">
            Veel e-learning tools en edu apps om talen te leren kunnen perfect checken of een antwoord juist of fout is.
            Het gaat dan typisch over orden-, invul- of meerkeuze-oefeningen voor woordenschat of grammatica.
            Komt het antwoord exact overeen met het verwachte antwoord? Dan is het juist, anders fout.
            Door het gemak waarmee deze oefeningen te maken zijn is er hier een groot aanbod voor.
            Je leert hiermee vooral losse woordjes en doet taalkennis op op een erg bevestigende manier:
            je leert zeggen wat goed of fout is. Je leert het niet zelf doen.
          </p>

          <p [innerHtml]="'corefeatures.open_answers_paragraph_2' | translate">
            Wil je in taalonderwijs volledige simulaties en gesprekken integreren,
            kom je echter uit bij het domein van “open antwoorden”.
            Dat is exact wat wij met Taalheld doen. En dit is pedagogisch gezien ook sterk onderbouwd.
            Deze aanpak past perfect in een taaltaken-methodologie (TBLT).
            In plaats van alleen woordenlijsten en werkwoordsvervoegingen te oefenen
            worden er praktische situaties geoefend wat tal van voordelen oplevert: de focus ligt op zelfredzaamheid,
            niet op het opbouwen van veel taaltechnische kennis.
            Het is doelgericht: men leert geen woorden of structuren buiten context,
            maar direct in de context waarin ze kunnen worden gebruikt; men krijgt feedback op het effectieve eigen gebruik van de taal
            en het is ervaringsleren: men leert al doende.
          </p>

          <h4 [translate]="'corefeatures.adaptivity'">Adaptiviteit</h4>

          <p [innerHtml]="'corefeatures.adaptivity_paragraph_1' | translate">
            Wij zetten sterk in op adaptiviteit en het personaliseren van de ervaring voor elke gebruiker - het mag voor niemand te makkelijk of te moeilijk zijn.
            Een aantal zaken zijn nu al beschikbaar in het spel:</p>
          <ul>
            <li [innerHtml]="'corefeatures.in_conversations' | translate">In gesprekken: de antwoorden van de personages passen zich automatisch aan je niveau aan en het systeem geeft sneller hints en kiest andere hints op basis van hoe je het de eerdere gesprekken deed.</li>
            <li [innerHtml]="'corefeatures.pratice_game' | translate">Oefenspel: je oefent exact de woorden en grammatica die je nodig hebt. Dit gebeurt op basis van algoritmes zoals gespreide herhaling en op basis van domeinkennis over bv. grammatica.
              We houden in detail bij wat je in het verleden goed deed en wat niet.
              Je oefent in het oefenspel ook exact de open antwoorden waarop je in de gesprekken fouten maakte.</li>
          </ul>
          <p [innerHtml]="'corefeatures.adaptivity_paragraph_2' | translate">
            We blijven intensief verder werken aan verbeteringen aan de adaptiviteit van de hele game.
            Eén van onze specialisten uit het team werkt aan state-of-the-art adaptiviteit voor open antwoorden en complexe taaltaken, dit samen met de Katholieke Universiteit Leuven (KUL).
          </p>

          <h4 [innerHtml]="'corefeatures.unique_speech_technology'| translate">Unieke spraaktechnologie</h4>

          <p [innerHtml]="'corefeatures.unique_speech_technology_paragraph' | translate">
            De meeste mensen leren een taal om ze dan ook te gaan spreken.
            Onderzoek toont ook aan dat de grootste voorspeller van het succesvol leren van een taal ook exact dat is: raak ik drempel over om de nieuwe taal te beginnen spreken?
            Echter, de huidige taalleeroplossingen focussen hier niet op.
            Onze competitie claimt vaak wel dat ze unieke spraaktechnologie inzetten. Maar ze hebben geen focus op open antwoorden en spraak en doen hierin dan ook geen investeringen.
            In de praktijk blijkt hun spraakherkenning vaak gewone third party spraakherkenning te zijn zoals die van Google of Amazon.
            Deze worden als aanvulling toegevoegd aan hun oefeningen met gesloten antwoorden. Onderzoek toont aan dat dit voor taalstudenten erg matige resultaten oplevert.
            Eigennamen worden bijvoorbeeld niet begrepen en woorden worden consequent fout herkend ondanks dat de herkende woorden op die plek helemaal niet passen.
            Het minste accent laat de spraakherkenning falen,...
            Onze gecontextualiseerde spraakherkenning lost dit op en maakt het gebruik van spraak leuk én echt.
            Daar stoppen we echter niet. Als enige op de markt bieden we automatische spraakanalyse aan die feedback geeft op ingesproken zinnen.
            We maken je er attent op als je woorden niet goed uitspreekt ook al hebben we wel herkend wat je zei.
          </p>
        </div>
        <div class="col-lg-5">
          <img src="assets/images/{{languageCode}}/screenshots/intro3.png" class="productDetail__screenshot"/>
          <img src="assets/images/{{languageCode}}/screenshots/conversation4.png" class="productDetail__screenshot"/>
          <!-- <img src="assets/images/nl/screenshots/oefenspel2.png" class="productDetail__screenshot"/>-->
           <img src="assets/images/{{languageCode}}/screenshots/cinematic4.png" class="productDetail__screenshot"/>
         </div>
       </div>
     </div>
   </div>

   <div class="gameFeaturesSection">
     <h2 class="text-center" [translate]="'gamefeatures.title'">Game features</h2>

     <div class="container">
       <div class="row pt-1 pb-1">
         <div class="col-lg-5">
           <!--<img src="assets/images/nl/screenshots/conversation5.png" class="productDetail__screenshot"/>-->
           <img src="assets/images/{{languageCode}}/screenshots/kaart2.png" class="productDetail__screenshot"/>
           <!--<img src="assets/images/nl/screenshots/cinematic3.png" class="productDetail__screenshot"/>-->
           <!--<img src="assets/images/nl/screenshots/vriendenboek2.png" class="productDetail__screenshot"/>-->
           <img src="assets/images/{{languageCode}}/screenshots/beloning2.png" class="productDetail__screenshot"/>
           <img src="assets/images/{{languageCode}}/screenshots/oefenspel3.png" class="productDetail__screenshot"/>
         </div>
         <div class="col-lg-7">
           <div [innerHtml]="'gamefeatures.game_features_paragraph' | translate">
             Naast de core features willen we talen leren gewoon leuk maken. We gaan daar verder in dan anderen.
             Er zijn zeker al leuke apps zijn maar het blijven wel apps - gamified apps.
             Taalheld is de naam van volwaardige "game" waard: met een sterk verhaal en een wel doordacht en attractief game design dat verschillende types gamers aanspreekt.

           </div>

           <h4 class="secondaryColor" [translate]="'gamefeatures.strong_story'">Sterk verhaal</h4>

           <p [innerHtml]="'gamefeatures.strong_story_paragraph' | translate">
             We willen de serious game zijn met het sterkste verhaal in de game-geschiedenis.
             Met de verschillende hoofd story arcs zoals de draken, de haviken, de mysterieuze ziekte,
             de oude wereld en de zich ontwikkelende character story arcs van
             Sensei, Rinc en zijn familie, Aife, Gip, Baldog, Zwart en co, creëren we een wereld die tot leven komt zoals een Zelda dat doet.

             We gaan ook voor de prijzen van “meest epische moment in een serious game” en “meest hilarische moment in een serious game” -
             stay tuned voor onze Kickstarter-campagne voor meer informatie hierover.
           </p>

           <h4 class="secondaryColor" [translate]="'gamefeatures.character_building'">“Form follows function” character building</h4>

           <p [innerHtml]="'gamefeatures.character_building_paragraph' | translate">
             We passen de concepten van character building zoals gekend van avonturenspellen toe op een taalleerspel.
             Het basisprincipe is hier “vorm volgt inhoud”.
             Het spel staat niet naast het talen leren als een soort van afleiding of beloning, het spel is het talen leren.
           </p>

           <b [translate]="'gamefeatures.stats'">Stats</b>
           <p [translate]="'gamefeatures.stats_paragraph'">
             De stats van het personage die na elke oefening verbeteren stellen exact de vooruitgang in de taal voor:
             1) vloeiendheid staat voor het “uw plan kunnen trekken”
             2) complexiteit voor “complexere taal kunnen gebruiken
             3) nauwkeurigheid voor het “geen fouten maken”
             Verder brengen de stats ook ook de afzonderlijke vaardigheden luisteren, schrijven, spreken en lezen in kaart net als de technische woordenschat-en grammaticakennis.
             Tenslotte is er ook het aantal geheimen dat men te weten is gekomen. Een geheim kom je te weten als je op bepaalde punten een ander antwoord geeft dan verwacht dat wel perfect op dat punt past.
             Geheimen zijn misschien wel de belangrijkste stat: we willen je uitnodigen zelf te proberen de taal te spreken ongeacht wat wij verwachten dat je zegt.
           </p>
           <b [translate]="'gamefeatures.friends_book'">Vriendenboek</b>
           <p [translate]="'gamefeatures.friends_book_paragraph'">
             Met elk virtueel personage waarmee je praat ontwikkel je een vriendschap.
             Naarmate de vriendschap vordert, zal je meer en meer over dat personage te weten komen en zal je er betere vrienden mee worden.
             Betere vrienden zullen je ook meer en beter uit de nood helpen als je daarom vraagt.
           </p>
           <b [translate]="'gamefeatures.equipment'">Uitrusting</b>
           <p [translate]="'gamefeatures.equipment_paragraph'">
             Je kan tijdens gesprekken voorwerpen krijgen, vragen kopen,…
             en die voorwerpen dan weer inruilen of weggeven om andere zaken te bekomen.
             De voorwerpen zijn geen losstaand element maar zijn deel van het onderhandelen en het opbouwen van vriendschappen
             terwijl je met de personages praat.
           </p>

           <h4 class="secondaryColor" [translate]="'gamefeatures.puzzle_and_search_game'">Puzzel- en zoekspel</h4>

           <p [innerHtml]="'gamefeatures.puzzle_and_search_game_paragraph' | translate">
             Taalheld is ook een puzzel- en zoekspel.
             Elk hoofdstuk heeft een verborgen laag die je niet vindt als je je alleen aan het script houdt.
             Om een tipje van de sluier op te lichten:
             in hoofdstuk 1 kan je voor Baldog de beer 3 extra dingen doen waar je andere personages voor nodig hebt.
             Deze 3 verborgen opdrachten leveren je de sleutel op die ertoe leidt dat je informatie
             zal kunnen opzoeken in de bibliotheek van het klooster waarmee je dan weer andere personages kan helpen.
           </p>

           <h4 class="secondaryColor" [translate]="'gamefeatures.adaptive_practice_game'">Adaptief oefenspel</h4>

           <ul>
             <li [innerHTML]="'gamefeatures.adaptive_practice_game_fun' | translate">Leuk: opbouwen van streaks en combo’s</li>
             <!--
               <li [innerHTML]="'gamefeatures.adaptive_practice_game_answers' | translate">Zowel 0 of 1 antwoorden als open antwoorden</li>
             -->
            <li [innerHTML]="'gamefeatures.adaptive_practice_game_strong_adaptive' | translate">Sterk adaptief</li>
            <li [innerHTML]="'gamefeatures.adaptive_practice_game_unblock_consolidate' | translate">Deblokkeren / consolideren na gesprekken</li>
            <li [innerHTML]="'gamefeatures.adaptive_practice_game_integrated' | translate">Geïntegreerd met de rest van het spel: character building / visualisatie wereld</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
