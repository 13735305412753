<a
  class="btn btn-primary playButton__button"
  [ngClass]="{'playButton__button--green': showAsGreen}"
  href="{{linkUrl}}" target="_blank">
  <img src="assets/images/store_icons/{{iconFilename}}" class="playButton__buttonImage"/>
  <div class="playButton__buttonText">
    <span class="playButton__downloadLocationIntro" [translate]="instructionLabelKey">Speel in je</span>
    <br/>
    <span class="playButton__downloadLocation">{{platformLabel}}</span>
  </div>
</a>
