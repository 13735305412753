<app-nav-bar></app-nav-bar>

<router-outlet></router-outlet>

<footer>
  <div class="container">
    <div class="row">
      <div class="col-9 text-left">&copy; 2020-2023 Linguineo BV, <a class="secondaryColor" href="mailto:taalheld@linguineo.com">taalheld@linguineo.com</a></div>
      <div class="col-3 text-right">
        <a href="https://linguineo.com/#!/privacy_policy" class="footerLink" target="_blank">Privacy</a>
      </div>
    </div>
  </div>
</footer>
