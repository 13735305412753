import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LandingComponent } from './views/landing/landing.component';
import { ProductDetailComponent } from './views/product-detail/product-detail.component';
import {ForSchoolsComponent} from "./views/for-schools/for-schools.component";
import {
  MurderOnTheLanguageTrainComponent
} from "./views/murder-on-the-language-train/murder-on-the-language-train.component";

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload'
};

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: LandingComponent  },
  { path: 'product-detail', component: ProductDetailComponent  },
  { path: 'for-schools', component: ForSchoolsComponent  },
  { path: 'murder-on-the-language-train', component: MurderOnTheLanguageTrainComponent  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
