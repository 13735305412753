import { Component, OnInit } from '@angular/core';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-murder-on-the-language-train',
  templateUrl: './murder-on-the-language-train.component.html',
  styleUrls: ['./murder-on-the-language-train.component.scss']
})
export class MurderOnTheLanguageTrainComponent implements OnInit {

  playUrl: string;

  INTERFACE_LANGUAGE_SHOULD_ALWAYS_BE_FRENCH = true;

  BASIC_SMART_SCHOOL_LOGIN_URL = 'https://api.taalheld.be/api/auth/oauth2/authorization/smartschool?redirect_uri=https%3A%2F%2Fapp.taalheld.be%2F%3FtargetLanguage%3Dfr%26specialCode%3Dspecial_murder_on_the_train%26styling%3Dbasic';
  BASIC_LEER_ID_LOGIN_URL = 'https://api.taalheld.be/api/auth/initiate-login?iss=https://authenticatie.vlaanderen.be&target_link_uri=https%3A%2F%2Fapp.taalheld.be%2F%3FtargetLanguage%3Dfr%26specialCode%3Dspecial_murder_on_the_train%26styling%3Dbasic'

  smartSchoolLoginUrl = this.BASIC_SMART_SCHOOL_LOGIN_URL;
  leerIdLoginUrl = this.BASIC_LEER_ID_LOGIN_URL;
  baseMurderOnTheLanguageTrainUrl = 'https://app.taalheld.be/?targetLanguage=fr&specialCode=special_murder_on_the_train&styling=basic&tutorLanguage=';

  constructor(
    public translate: TranslateService) { }

  public getCurrentTutorLang(): string {
    if (this.INTERFACE_LANGUAGE_SHOULD_ALWAYS_BE_FRENCH){
      return 'fr';
    } else {
      return this.translate.currentLang;
    }
  }

  setPlayUrlInCorrectLanguage(): void {
    const fullPlayUrl = this.baseMurderOnTheLanguageTrainUrl + this.getCurrentTutorLang();
    this.playUrl = fullPlayUrl;
  }

  refreshSmartschoolLoginUrl(): void {
    this.smartSchoolLoginUrl = this.BASIC_SMART_SCHOOL_LOGIN_URL + '%26tutorLanguage%3D' + this.getCurrentTutorLang();
  }
  refreshLeerIdLoginUrl(): void {
    this.leerIdLoginUrl = this.BASIC_LEER_ID_LOGIN_URL + '%26tutorLanguage%3D' + this.getCurrentTutorLang();
  }

  ngOnInit() {
    this.setPlayUrlInCorrectLanguage();
    this.refreshSmartschoolLoginUrl();
    this.refreshLeerIdLoginUrl();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setPlayUrlInCorrectLanguage();
      this.refreshSmartschoolLoginUrl();
      this.refreshLeerIdLoginUrl();
    });
  }

}
