import { Injectable } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {

  supportedLanguages = ['nl', 'en', 'fr'];

  constructor() {
  }

  public getSupportedLanguages(): string[] {
    return this.supportedLanguages;
  }
}
