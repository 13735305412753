<div class="murderOnTheLanguageTrain">
  <div class="murderOnTheLanguageTrain__top">
    <div class="container">

      <h2>
        <span [translate]="'murder_on_the_language_train.title'">Murder on the Language Train</span>&nbsp;(Bootc@mp Frans)
      </h2>

      <div class="row py-1">
        <div class="col-lg-7">
          <div [translate]="'murder_on_the_language_train.explanation'">
            Met Moord op de Taaltrein is een game-based en gepersonaliseerd oefenplatform waarmee we leerlingen en leerkrachten willen ondersteunen bij het versterken van het Frans taalonderwijs in Vlaanderen. We richten ons hierbij op de derde graad van het secundair onderwijs met oog op een succesvolle doorstroming naar het hoger onderwijs. Ons project biedt een aantrekkelijke en motiverende game die aansluit op de leefwereld van het doelpubliek. De game bovendien een dashboard voor het visualiseren van het leerproces per individuele leerling. Met Moord op de Taaltrein krijgt de leerkracht zo een tool in handen om de vooruitgang van elke leerling op te volgen en zo beter in te kunnen spelen op de noden van elke leerling.
          </div>
          <div class="py-2">
            <span [translate]="'murder_on_the_language_train.partners'">Consortiumpartners</span>:
            <a href="https://sint-pieterscollege.be/" target="_blank">Sint-Pieterscollege Jette</a>,
            <a href="https://sintpieterscollege.be/" target="_blank">Sint-Pieterscollege Leuven</a>,
            <a href="https://ilt.kuleuven.be/" target="_blank">KU Leuven - Instituut voor Levende Talen (ILT)</a>,
            <a href="https://www.digitalartsandentertainment.be/page/133/Research" target="_blank">Howest – Digital Arts and Entertainment research</a>&nbsp;<span [translate]="'label.and'">and</span>&nbsp;<a href="https://www.linguineo.com" target="_blank">Linguineo BV</a>.
          </div>
          <div class="py-2">
            <img src="assets/images/partner_logos/spcj_dark.png" class="murderOnTheLanguageTrain__partnerImage">
            &nbsp;
            <img src="assets/images/partner_logos/spcl_dark.png" class="murderOnTheLanguageTrain__partnerImage">
            &nbsp;
            <img src="assets/images/partner_logos/ILT.png" class="murderOnTheLanguageTrain__partnerImage">
            &nbsp;
            <img src="assets/images/partner_logos/Howest_black.png" class="murderOnTheLanguageTrain__partnerImage">
            &nbsp;
            <img src="assets/images/partner_logos/linguineo-logo.png" class="murderOnTheLanguageTrain__partnerImage">
          </div>

          <h4 [translate]="'label.how_to_play'">Hoe spelen?</h4>

          <div class="pt-2 pb-4">
            <span [innerHTML]="'murder_on_the_language_train.ways_to_play_before' | translate: {smartSchoolLoginUrl: smartSchoolLoginUrl, leerIdLoginUrl: leerIdLoginUrl}">
               Er zijn op dit moment 3 manieren om Moord op de Taaltrein te spelen: via Smartschool, via LeerId, via i-learn of via</span>&nbsp;<a href="{{playUrl}}"><span [translate]="'murder_on_the_language_train.ways_to_play_direct_link'">de rechtstreekse link</span></a>.
          </div>

          <h5>1. <span [innerHTML]="'murder_on_the_language_train.via_smart_school' | translate">via Smartschool</span></h5>

          <div class="pt-2 pb-2">
            <span [innerHTML]="'murder_on_the_language_train.via_smartschool_explanation' | translate: {smartSchoolLoginUrl: smartSchoolLoginUrl}">
              Als je school gebruik maakt van Smartschool, kan je <a href="https://api.taalheld.be/api/auth/oauth2/authorization/smartschool">direct aanmelden via deze link</a>.
            </span>
          </div>

          <h5>2. <span [innerHTML]="'murder_on_the_language_train.via_leer_id' | translate">via LeerId</span></h5>

          <div class="pt-2 pb-2">
            <span [innerHTML]="'murder_on_the_language_train.via_leer_id_explanation' | translate: {leerIdLoginUrl: leerIdLoginUrl}">
              Als je school gebruik maakt van LeerId, kan je <a href="https://api.taalheld.be/api/auth/oauth2/authorization/smartschool">direct aanmelden via deze link</a>.
            </span>
          </div>

          <h5>3. <span [innerHTML]="'murder_on_the_language_train.via_i-learn' | translate">via i-learn</span></h5>

          <div class="pt-2 pb-2">
            <span [innerHTML]="'murder_on_the_language_train.via_i-learn_explanation' | translate">
              Om van i-learn gebruik te kunnen maken moet je school eerst <a href="https://www.i-learn.be/word-i-learn-school">bij i-learn registeren</a>. Vervolgens zal je als leerkracht of leerling op het i-learn portaal kunnen aanmelden. Eens aangemeld kan via het zoeksysteem op i-learn MyWay door de zoekterm "Moord op de Taaltrein" in te geven de juiste leeractiviteit terugvinden en kan het spel van daaruit rechtstreeks gestart worden zonder dat een bijkomende login nodig is. Voor leerkrachten zullen de leerresultaten van hun leerlingen via deze weg ook rechtstreeks verschijnen in het gekoppelde dashboard.
            </span>
          </div>

          <img src="assets/images/ilearn/ilearn_murder_language_train.png" class="murderOnTheLanguageTrain__inlineScreenshot pt-2 pb-4"/>

          <h5>4. <span [innerHTML]="'murder_on_the_language_train.via_direct_link' | translate">via de rechtstreekse link</span></h5>

          <div class="py-2">
            <span [innerHTML]="'murder_on_the_language_train.via_direct_link_explanation' | translate">
              Je kan ook via de knop hieronder de tool opstarten maar dan is het wel nodig om een specifieke login op ons systeem aan te maken. We vragen op zijn minst een gebruikersnaam en idealiter vul je ook je e-mail in om accountherstel achteraf mogelijk te maken. Die login is nodig om leerresultaten en vooruitgang in het spel te kunnen bewaren.
            </span>
          </div>

          <div class="pt-1">

            <app-play-button
              [linkUrl]="playUrl"
              [iconFilename]="'webgl.png'"
              [instructionLabelKey]="'download.play_directly_from'"
              [platformLabel]="'browser'"
              [showAsGreen]="true"></app-play-button>

          </div>

          <h4 [translate]="'label.manuals'" class="mt-3">Manuals</h4>

          <p>
            <span [translate]="'label.find_more_info_in_manuals'">
              You can find more info on how to use the game in the manuals</span>:
          </p>

          <ul>
            <li>
              <a href="assets/pdfs/HandleidingTaalheldLeerlingen.pdf" target="_blank">
                <span [translate]="'label.manual_for_students'">Handleiding voor leerlingen</span>
              </a>:
              <span [translate]="'label.how_to_use_game'">hoe het spel gebruiken?</span></li>
            <li>
              <a href="assets/pdfs/HandleidingTaalheldScholen.pdf" target="_blank">
                <span [translate]="'label.manual_for_schools'">Handleiding voor scholen</span>
              </a>:
              <span [translate]="'label.how_to_use_dashboard'">hoe het dashboard gebruiken?</span></li>
          </ul>
        </div>
        <div class="col-lg-5">
          <img src="assets/images/fr/screenshots/murder_on_the_language_train/screenshot_09.png" class="murderOnTheLanguageTrain__screenshot"/>
          <img src="assets/images/fr/screenshots/murder_on_the_language_train/screenshot_10.png" class="murderOnTheLanguageTrain__screenshot"/>
          <img src="assets/images/fr/screenshots/murder_on_the_language_train/screenshot_12.png" class="murderOnTheLanguageTrain__screenshot"/>
          <img src="assets/images/fr/screenshots/murder_on_the_language_train/screenshot_14.png" class="murderOnTheLanguageTrain__screenshot"/>
          <img src="assets/images/fr/screenshots/murder_on_the_language_train/screenshot_13.png" class="murderOnTheLanguageTrain__screenshot"/>
        </div>
      </div>
    </div>
  </div>
</div>
