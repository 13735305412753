<div class="container">
  <nav class="navbar navbar-expand-lg navbar-light">
    <a class="navbar-brand" routerLink="/">
      <img src="assets/images/nl/taalheld_logo.png" class="navbar__logo" alt="Taalheld" *ngIf="currentLang === 'nl'"/>
      <img src="assets/images/en/taalheld_logo_eng.png" class="navbar__logo" alt="Taalheld" *ngIf="currentLang !== 'nl'"/>
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="/" [translate]="'label.home'">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/product-detail" [translate]="'label.our_game'">Onze game</a>
        </li>
        <!--
        <li class="nav-item">
          <a class="nav-link" href="#">Modules</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Voor wie?</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Team</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Contact</a>
        </li>
        -->
      </ul>

      <ul class="navbar-nav mr-auto">
        <li class="nav-item" *ngFor="let supportedLanguage of supportedLanguages">
          <a class="nav-link text-uppercase" (click)="switchToLanguage(supportedLanguage)" [ngClass]="{'font-weight-bold': currentLang === supportedLanguage}">{{supportedLanguage}}</a>
        </li>
      </ul>

      <form class="form-inline">
        <div class="navbar__buttonGroup">
          <a class="btn typicalButton--normal navbar__button navbar__button--big" routerLink="/home" fragment="downloadSection" [translate]="'label.install_for_free'">Installeer gratis</a>
          <a href="https://www.facebook.com/LanguageHeroGame" target="_blank" class="btn btn-outline typicalButton--outline navbar__button navbar__button--small">
            <img src="assets/images/icons/facebook.png"/>
          </a>
          <!--
          <button class="btn btn-outline typicalButton--outline navbar__button navbar__button--small" type="button">
            <img src="assets/images/icons/twitter.png"/>
          </button>
          <button class="btn btn-outline typicalButton--outline navbar__button navbar__button--small" type="button">
            <img src="assets/images/icons/youtube.png"/>
          </button>
          -->
        </div>
      </form>
    </div>
  </nav>
</div>
