<div class="landing">
  <div class="introductionSection">
    <div class="container">
      <div class="row introductionSection__row">
        <div class="col-lg-6">
          <div class="introductionSection__topLeft">
            <h2>
              <span class="secondaryColor" [translate]="'introduction.will_your_children_become_language_heroes'">Worden jouw kinderen ook taalhelden?</span><br/>
            </h2>
            <!--
            <p class="introductionSection__introParagraph" [innerHtml]="'introduction.intro_paragraph' | translate">
              Bang dat je kinderen een taalachterstand oplopen? Of meer taaluitdagingen aan het zoeken voor jezelf?
              Dompel jezelf of je kind onder in een sprankelend sprookje waar je leuke personages ontmoet, met hen spreekt, en zo spelenderwijs Frans leert.
              Met bewezen resultaten én onderschreven door erkende pedagogen.
            </p>
            -->

            <div class="pr-2 py-2">
              <span class="introductionSection__introParagraph" [translate]="'introduction.available_for'">
                Beschikbaar voor:
              </span>
              <span class="introductionSection__introParagraph">
                <img class="introductionSection__flagIcon" src="assets/images/flags/france@2x.png"/> <span [translate]="'language.fr'">Frans</span>
              </span>
              <span class="introductionSection__introParagraph">
                <img class="introductionSection__flagIcon" src="assets/images/flags/england@2x.png"/> <span [translate]="'language.en'">Engels</span>
              </span>
              <span class="introductionSection__introParagraph">
                <img class="introductionSection__flagIcon" src="assets/images/flags/netherlands@2x.png"/> <span [translate]="'language.nl'">Nederlands</span>
              </span>
            </div>

            <div class="introductionSection__buttonGroup">
              <a class="btn typicalButton--normal navbar__button navbar__button--big" [routerLink]="'.'" fragment="downloadSection" [translate]="'introduction.try_it_for_free'">
                Probeer het gratis
              </a>
              <a class="btn btn-outline typicalButton--outline navbar__button navbar__button--small" routerLink="/product-detail" [translate]="'introduction.more_about_language_hero'">
                Meer over Taalheld
              </a>
            </div>

            <div class="introductionSection__trailer" *ngIf="trailerUrl">
              <div class="embed-responsive embed-responsive-4by3">
                <iframe class="embed-responsive-item" [src]="trailerUrl"></iframe>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 introductionSection__sideImage">
          <img src="assets/images/art/{{languageCode}}/laptopImage.png" class="introductionSection__image"/>
        </div>
      </div>
      <div class="row introductionSection__row">
        <div class="col-lg-3 col-sm-6 text-center">
          <img src="assets/images/game_icons/icon_gift.png" class="gameIcon"/>
          <h4 [translate]="'introduction.free_module_french'">Gratis module FRANS goed voor uren gratis taallessen</h4>
          <p [innerHtml]="'introduction.corona_crisis' | translate">Door de corona-crisis lanceren we ons leerplatform vele maanden eerder dan voorzien.</p>
        </div>
        <div class="col-lg-3 col-sm-6 text-center">
          <img src="assets/images/game_icons/icon_mission.png" class="gameIcon"/>
          <h4 [translate]="'introduction.3d_game_interactive'">3D-game als interactief platform voor kids</h4>
          <p [innerHtml]="'introduction.screen_time' | translate">Waarom de schermtijd voor de thuisblijvers niet fun én leerrijk invullen met een cool taalspel.</p>
        </div>
        <div class="col-lg-3 col-sm-6 text-center">
          <img src="assets/images/game_icons/icon_medal_gold.png" class="gameIcon"/>
          <h4 [translate]="'introduction.top_educators_top_game_developers'">Top pedagogen & top game developers in één project</h4>
          <p [innerHtml]="'introduction.proved_results' | translate">Bewezen resultaten door de nieuwste technologieën en pedagogische inzichten in te zetten om effectiever talen te leren.</p>
        </div>
        <div class="col-lg-3 col-sm-6 text-center">
          <img src="assets/images/game_icons/icon_setting.png" class="gameIcon"/>
          <h4 [translate]="'introduction.adaptive_learning'">Adaptief leren is gepersonaliseerd leren</h4>
          <p [innerHtml]="'introduction.easier_said_then_done' | translate">Gemakkelijker gezegd dan gedaan! We slagen erin kinderen op maat en op eigen tempo een taal aan te leren.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="detailSection typicalSection">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="detailSection__left">
            <h3 [translate]="'detail.learn_french_in_a_playful_way'">Spelenderwijs Frans leren!</h3>

            <p [innerHtml]="'detail.learn_french_in_a_playful_way_paragraph' | translate">
              Kinderen of volwassenen, ieder kent dezelfde uitdagingen om een vreemde taal te leren.
              Wat als dit echt spelenderwijs kan in een 3D-spel met moderne game features en game mechanics in een verrassende online wereld?
              Taalheld bouwt nu versneld aan zijn online platform met steeds weer nieuwe taalmodules en spelniveaus.
              Geen 0 of 1 antwoorden, maar open vragen van virtuele personages die echt met je in gesprek gaan!
              En dit op jouw tempo, in jouw tijd, aangepast op jouw niveau, in een veilige en comfortabele omgeving.</p>

            <div class="detailSection__links">
              <!--
              <ngb-accordion closeOthers="true">
                <ngb-panel>
                  <ng-template ngbPanelTitle>
                    <h5 class="detailSection__link" [translate]="'detail.exceptional_game'">Uitzonderlijke crisis? Uitzonderlijke game!</h5>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div [innerHtml]="'detail.exceptional_game_paragraph' | translate">
                      Hoe cool zou het zijn als we naast het leren van de vreemde taal ook de tips meenemen die we allemaal moeten kennen om verdere verspreiding van het corona-virus te stoppen en daarmee levens te redden?
                      Linguineo programmeerde voor Taalheld de tips en tricks effectief in haar game!
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel>
                  <ng-template ngbPanelTitle>
                    <hr/>
                    <h5 class="detailSection__link" [translate]="'detail.something_for_your_kids'">Iets voor jou of jouw kinderen?</h5>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div [innerHtml]="'detail.something_for_your_kids_paragraph' | translate">
                      Gaming is niet meer uit onze samenleving weg te denken, niet bij jongeren, maar zeker ook niet bij volwassenen. Wat als je deze trend kan combineren met de uitdaging van een taal te leren? Iedereen die oud genoeg is om te
                      gamen of een interactief online te spelen, kan met Taalheld grote stappen zetten in het leren van een vreemde taal!
                      Onze leeftijdscategorie? Van 8 tot 88 jaar.
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel>
                  <ng-template ngbPanelTitle>
                    <hr/>
                    <h5 class="detailSection__link" [translate]="'detail.with_result'">Met resultaat!</h5>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div [innerHtml]="'detail.with_result_paragraph' | translate">
                      Een innovatieve en effectieve taalleergame waar zowel kinderen, volwassen gamers als pedagogische mensen van over de hele wereld vrolijk van worden.
                      Miljoenen mensen gebruikten eerder al de andere taal-apps en taalspellen van Linguineo, het bedrijf dat Taalheld ontwikkelde.
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel>
                  <ng-template ngbPanelTitle>
                    <hr/>
                    <h5 class="detailSection__link" [translate]="'detail.scientifically_proven'">Wetenschappelijk bewezen!</h5>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div [innerHtml]="'detail.scientifically_proven_paragraph' | translate">
                      Taalheld past perfect in een taaltaken-methodologie (TBLT).
                      In plaats van alleen woordenlijsten en werkwoordsvervoegingen te oefenen worden er praktische situaties geoefend wat tal van voordelen oplevert:
                      de focus ligt op het uw plan kunnen trekken, niet op het opbouwen van veel taaltechnische kennis.
                      Het is doelgericht: men leert geen woorden of structuren buiten context maar direct in de context waarin ze kunnen worden gebruikt;
                      men krijgt feedback op het effectieve eigen gebruik van de taal, en het is ervaringsleren: men leert al doende.
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
              -->
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <img src="assets/images/art/{{languageCode}}/detail_image.png" class="detailSection__image"/>
        </div>
      </div>
    </div>
  </div>

  <div class="whyBetterSection typicalSection">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="whyBetterSection__left">
            <div class="whyBetterSection__greenFlag">
              <img src="assets/images/small_art/green_flag_big.png"/>
            </div>

            <h3 [translate]="'whybetter.title'">Waarom werkt ons systeem beter dan andere taalplatformen?</h3>
            <br/>
            <p [innerHtml]="'whybetter.title_paragraph' | translate">
              Er zijn tientallen aanbieders van taalleeroplossingen.
              En deze markt blijft maar groeien.
              Maar waarom haken we vaak toch snel af in ons leertraject?
              En hoe weet je zeker dat je de snelste en meest effectieve methode gebruikt?
              20 jaar kennis en ervaring ivm taalwetenschap, pedagogiek en edu-tech leerde ons alvast volgende vier sleutels te ontdekken.
              Maar bovenal: maak het leuk, gepersonaliseerd en interactief!
            </p>

            <div class="pt-3">
              <a class="btn typicalButton--normal navbar__button navbar__button--big whyBetterSection__button" routerLink="/product-detail" [translate]="'whybetter.view_all_the_details'">
                Bekijk alle details
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-3">
          <div class="row">
            <div class="col-lg-6 col-sm-6 text-center">
              <img src="assets/images/game_icons/icon_sword.png" class="gameIcon"/>
              <h4 [translate]="'whybetter.speaking_and_writing_in_a_playful_way'">Spelenderwijs spreken én schrijven in een online game.</h4>
              <p [translate]="'whybetter.speaking_and_writing_in_a_playful_way_paragraph'">Een prachtige virtuele 3D-wereld waar je level na level, module na module, weer op zoek gaat naar de juiste oplossingen.</p>
            </div>
            <div class="col-lg-6 col-sm-6 text-center">
              <img src="assets/images/game_icons/icon_heindal.png" class="gameIcon"/>
              <h4 [translate]="'whybetter.conversing_with_real_characters'">Converseren met echte personages.</h4>
              <p [translate]="'whybetter.conversing_with_real_characters_paragraph'">Je antwoordt het personage en krijgt live gepersonaliseerde feedback op wat je zegt of schrijft.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-sm-6 text-center">
              <img src="assets/images/game_icons/icon_target.png" class="gameIcon"/>
              <h4 [translate]="'whybetter.personalized'">Gepersonaliseerd en op het juiste tempo.</h4>
              <p [translate]="'whybetter.personalized_paragraph'">Onze adaptieve algoritmes geven jou op jouw niveau, jouw tempo en de uitdagingen die je nodig hebt.</p>
            </div>
            <div class="col-lg-6 col-sm-6 text-center">
              <img src="assets/images/game_icons/icon_energy.png" class="gameIcon"/>
              <h4 [translate]="'whybetter.even_your_pronunciation'">Zelfs je uitspraak wordt bijgestuurd.</h4>
              <p [translate]="'whybetter.even_your_pronunciation_paragraph'">Wij luisteren goed naar je antwoord en combineren dit met zijn context. Vanzelfsprekend? Niet voor 99% van de online taalleerplatformen!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="downloadSection typicalSection">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h2 id="downloadSection" [innerHtml]="'download.three_simple_steps' | translate">Drie simpele stappen om een Taalheld te worden.</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="downloadSection__center">
            <div class="row">
              <div class="col-md-4 downloadSection__step">
                <div class="downloadSection__stepHeader" [translate]="'download.install'">Installeer</div>
                <div class="downloadSection__stepLinkContainer">
                  <div class="downloadSection__stepLinkLine"></div>
                  <div class="downloadSection__stepLink" [ngClass]="{'downloadSection__stepLink--active': currentStep === 'install'}" role="button" (click)="switchToDownloadStep('install')">
                    1
                  </div>
                </div>
              </div>
              <div class="col-md-4 downloadSection__step">
                <div class="downloadSection__stepHeader" [translate]="'download.register'">Registreer</div>
                <div class="downloadSection__stepLinkContainer">
                  <div class="downloadSection__stepLinkLine"></div>
                  <div class="downloadSection__stepLink" [ngClass]="{'downloadSection__stepLink--active': currentStep === 'register'}" role="button" (click)="switchToDownloadStep('register')">
                    2
                  </div>
                </div>
              </div>
              <div class="col-md-4 downloadSection__step">
                <div class="downloadSection__stepHeader" [translate]="'download.start_free'">Start gratis</div>
                <div class="downloadSection__stepLinkContainer">
                  <div class="downloadSection__stepLinkLine"></div>
                  <div class="downloadSection__stepLink" [ngClass]="{'downloadSection__stepLink--active': currentStep === 'start'}" role="button" (click)="switchToDownloadStep('start')">
                    3
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="currentStep === 'install'">
        <div class="col-lg-9">
          <h4 [translate]="'download.install_the_application'">Installeer de applicatie</h4>
          <p class="downloadSection__paragraph" [translate]="'download.install_the_application_paragraph'">Download en installeer de game gratis vanaf deze pagina voor iPhone/iPad, Android, Windows of Mac OSX.</p>
        </div>
        <div class="col-lg-3">
        </div>
      </div>
      <div class="row" *ngIf="currentStep === 'register'">
        <div class="col-lg-9">
          <h4 [translate]="'download.register_in_5_seconds'">Registreer in 5 seconden</h4>
          <p class="downloadSection__paragraph" [translate]="'download.register_in_5_seconds_paragraph'">Je hoeft alleen een gebruikersnaam in te geven en akkoord gaan met het verwerken van je inputs.
            Een email opgeven is optioneel maar wel aangeraden als je je vooruitgang in het spel niet wil verliezen.</p>
        </div>
        <div class="col-lg-3">
          <img src="assets/images/nl/screenshots/maak_account.png" class="downloadSection__screenshot"/>
        </div>
      </div>
      <div class="row" *ngIf="currentStep === 'start'">
        <div class="col-lg-9">
          <h4 [translate]="'download.start_free'">Start gratis</h4>
          <p class="downloadSection__paragraph" [translate]="'download.start_free_paragraph'">Je kan direct aan de slag.</p>
        </div>
        <div class="col-lg-3">
          <img src="assets/images/nl/screenshots/kaart_start.png" class="downloadSection__screenshot"/>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-4 col-md-2 text-center py-1">
          <app-play-button
            [linkUrl]="'https://apps.apple.com/app/language-hero-by-linguineo/id1520245350'"
            [iconFilename]="'apple.png'"
            [instructionLabelKey]="'download.download_on_the'"
            [platformLabel]="'App Store'"></app-play-button>
        </div>
        <div class="col-6 col-md-3 text-center py-1">
          <app-play-button
            [linkUrl]="'https://play.google.com/store/apps/details?id=com.linguineo.languagehero'"
            [iconFilename]="'google_play.png'"
            [instructionLabelKey]="'download.download_on_the'"
            [platformLabel]="'Google Play'"></app-play-button>
        </div>
        <div class="col-4 col-md-2 text-center py-1">
          <app-play-button
            [linkUrl]="'https://apps.microsoft.com/store/detail/language-hero/9NX2BB92R3Q7'"
            [iconFilename]="'windows.png'"
            [instructionLabelKey]="'download.download_on_the'"
            [platformLabel]="'Windows'"></app-play-button>
        </div>
        <div class="col-6 col-md-3 text-center py-1">
          <app-play-button
            [linkUrl]="'https://apps.apple.com/app/language-hero-by-linguineo/id1520245350'"
            [iconFilename]="'apple.png'"
            [instructionLabelKey]="'download.download_on_the'"
            [platformLabel]="'Mac OSX'"></app-play-button>
        </div>
        <div class="col-4 col-md-2 text-center py-1">
          <app-play-button
            [linkUrl]="'https://app.taalheld.be'"
            [iconFilename]="'webgl.png'"
            [instructionLabelKey]="'download.play_directly_from'"
            [platformLabel]="'browser'"></app-play-button>
        </div>
      </div>
    </div>
  </div>

  <div class="supportedBySection typicalSection">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center supportedBySection__partnersIntro" [translate]="'supportedby.made_possible_by'">
          Mee mogelijk gemaakt door
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 text-center supportedBySection__partner">
          <div class="supportedBySection__partnerLogoContainer">
            <a href="https://www.vlaio.be" target="_blank">
              <img src="assets/images/partners/vlaio.png" class="supportedBySection__partnerLogo"/>
            </a>
          </div>
        </div>
        <div class="col-md-4 text-center supportedBySection__partner">
          <div class="supportedBySection__partnerLogoContainer">
            <a href="https://www.vaf.be" target="_blank">
              <img src="assets/images/partners/vaf.jpg" class="supportedBySection__partnerLogo"/>
            </a>
          </div>
        </div>
        <div class="col-md-4 text-center supportedBySection__partner">
          <div class="supportedBySection__partnerLogoContainer">
            <a href="https://www.imec-int.com/en/istart" target="_blank">
              <img src="assets/images/partners/imec-istart.png" class="supportedBySection__partnerLogo"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
