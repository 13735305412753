import { Component, OnInit } from '@angular/core';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  trailerUrl: SafeUrl;

  public currentStep: string = 'install';
  public languageCode: string;

  constructor(
    public translate: TranslateService,
    private sanitizer: DomSanitizer) { }

  setTrailerInCorrectLanguage(): void {
    if (this.translate.currentLang === 'nl') {
      this.languageCode = this.translate.currentLang;
      this.trailerUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/o7_zShHPNyg');
    }
    else if (this.translate.currentLang === 'fr') {
      this.languageCode = this.translate.currentLang;
      this.trailerUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/BImLdGGs-kE');
    }
    else {
      // EN
      this.languageCode = 'en';
      this.trailerUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/VVqSpyLwdmg');
    }
  }

  ngOnInit() {
    this.setTrailerInCorrectLanguage();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setTrailerInCorrectLanguage();
    });
  }

  switchToDownloadStep(currentStep: string): void {
    this.currentStep = currentStep;
  }

}
